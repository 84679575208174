<template>
  <div class="period-selection">
    <div class="period-selection__date-picker">
      <VueDatePicker
        v-model="inputDateRange"
        :format="format"
        range
        multi-calendars
        auto-apply
        :enable-time-picker="false"
        :placeholder="
          $t('dashboard.filters.time.customPeriodModal.dateRangePlaceholder')
        "
        :inline="{ input: true }"
        @cleared="cleared"
        @update:model-value="updateValue"
      >
        <template #input-icon>
          <i class="date-picker__icon icon-date-range" />
        </template>
      </VueDatePicker>
    </div>
  </div>
</template>

<script lang="ts">
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { formatDate2 } from '~/services/date'

export default {
  name: 'PeriodSelection',
  components: { VueDatePicker },
  props: {
    initialValue: {
      type: Array as PropType<string[]>,
      default: () => []
    }
  },
  emits: ['update-value'],
  data(): {
    inputDateRange: Date[] | string[]
  } {
    return {
      inputDateRange: this.initialValue
    }
  },
  methods: {
    updateValue(newValue: Date[]) {
      if (newValue && newValue.length === 2) {
        this.$emit('update-value', newValue)
      } else {
        this.$emit('update-value', [])
      }
    },
    format(dateRange: Date[]) {
      return `${formatDate2(dateRange[0]!)} - ${formatDate2(dateRange[1]!)}`
    },
    cleared() {
      this.inputDateRange = []
    }
  }
}
</script>

<style lang="scss" scoped>
.period-selection {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 100%;
  width: 560px;
  height: 400px;

  &__date-picker {
    :deep(.dp__main) {
      align-items: center;
    }

    :deep(.dp__outer_menu_wrap) {
      margin-top: $margin-medium;
    }

    :deep(.dp__input) {
      padding: 10px 10px 10px 40px;
      @include font-text;
      @include font-size($font-size-regular);
      border: 1px solid $border-primary;
      border-radius: 20px;
      width: 268px;
    }

    .date-picker {
      &__icon {
        margin: 0 4px 0 $margin-regular;
        @include font-icon;
        @include font-size($font-size-intermediate, 36px);
        color: $text-secondary;
      }
    }
  }
}
</style>
